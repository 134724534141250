const BACKEND_HOST = process.env.BACKEND_HOST;

export default {
  translations: `${BACKEND_HOST}/endpoints/submissionApi/translations`,
  authRequest: `${BACKEND_HOST}/endpoints/submissionApi/authRequest`,
  formTranslations: `${BACKEND_HOST}/endpoints/submissionApi/formTranslations`,
  placeImages: `${BACKEND_HOST}/endpoints/submissionApi/placeImages`,
  imageDelete: `${BACKEND_HOST}/endpoints/submissionApi/deleteImage`,
  imageUpload: `${BACKEND_HOST}/endpoints/submissionApi/uploadImage`,
  getImage: `${BACKEND_HOST}/api/files/places`,
  autoSave: `${BACKEND_HOST}/endpoints/submissionApi/autoSave`,
  submit: `${BACKEND_HOST}/endpoints/submissionApi/submit`,
  selectOptions: `${BACKEND_HOST}/endpoints/submissionApi/selectOptions`,
  submission: `${BACKEND_HOST}/endpoints/submissionApi/submission`,
  publication: `${BACKEND_HOST}/endpoints/submissionApi/publication`,
  place: `${BACKEND_HOST}/endpoints/submissionApi/place`,
  revision: `${BACKEND_HOST}/endpoints/submissionApi/revision`,
  translation: `${BACKEND_HOST}/endpoints/submissionApi/translation`,
}