import React from "react";
import axios from "axios";
import endpoints from "../../utils/endpoints";
import { Space, Upload, Alert, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const ALLOWED_MIME_TYPES = [
  {
    type: 'image/png',
    extensions: ['png']
  },
  {
    type: 'image/jpeg',
    extensions: ['jpg', 'jpeg']
  },
  { 
    type: 'image/tiff', 
    extensions: ['tiff', 'tif'] 
  }, 
  { 
    type: 'image/heif', 
    extensions: ['heif'] 
  },
  { 
    type: 'image/heic', 
    extensions: ['heic'] 
  }, 
  {
    type: 'application/pdf',
    extensions: ['pdf']
  },
  {
    type: 'image/bmp',
    extensions: ['bmp']
  },
  {
    type: 'image/svg+xml',
    extensions: ['svg']
  },
  {
    type: 'image/gif',
    extensions: ['gif']
  }
]


const PlaceImageUpload = ({
  translations, 
  uuid, 
  passcode, 
  message,
  placeId,
  imageFileList, 
  setImageFileList
}) => {
  const uploadProps = {
    beforeUpload: (file) => {
      
      const fileTypeIsAllowed = ALLOWED_MIME_TYPES.map(ft => ft.type).includes(file.type);
      if (!fileTypeIsAllowed) {
        message.error(translations?.fileTypeNotAllowed);
      }

      const fileIsTooLarge = file.size > 10000000;
      if (fileIsTooLarge) {
        message.error(translations?.fileSizeTooLarge);
        return Upload.LIST_IGNORE;
      }
      
      const fileNameNotInUse = !imageFileList.map(f => f.name).includes(file.name);
      if (!fileNameNotInUse) {
        message.error(translations?.fileNameAlreadyInUse);
      }
      return (fileTypeIsAllowed && fileNameNotInUse && !fileIsTooLarge) || Upload.LIST_IGNORE;
    },
    onRemove: async (file) => {
      const {confirm} = Modal
        return new Promise((resolve, reject) => {
          confirm({
            title: translations?.confirmImageDelete,
            content: file.name,
            okText: translations?.confirm,
            cancelText: translations?.cancel,
            onOk: async() => {
              resolve(true);
              await axios.post(endpoints.imageDelete, {uuid, file, passcode});
            },
            onCancel: () => {
              resolve(false);
            }
          });
        });
    },
    onChange: async ({ fileList }) => {
      // find the file that's new and add the url key and value to it
      // it is the placeImage endpoint with uuid and image name as query params
      const newFile = fileList.find(f => !f.url)
      
      if (newFile?.response?.name) {
        newFile.url = `${endpoints.getImage}/${placeId}/${newFile.response.name}`
      }
      setImageFileList(fileList)
    },
    fileList: imageFileList,
    listType: 'picture-card',
    customRequest: async(options) => {
      const formData = new FormData();
      const encodedFileName = encodeURIComponent(options.file.name);
      const newFile = new File([options.file], encodedFileName, { type: options.file.type });
      formData.append('file', newFile);
      formData.append('uuid', uuid);
      formData.append('passcode', passcode);
    
      try {
        const response = await axios.post(endpoints.imageUpload, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const newFile = new File([options.file], response.data.name, { type: options.file.type })
        options.onSuccess(response.data, newFile);
      } catch (error) {
        // Handle the error here
        console.error('Error uploading file:', error);
        options.onError(error);
      }
    }
  };

  return (
    <>
      <Space direction="vertical" size="middle">
        <Upload
          {...uploadProps}
        >
          <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        </Upload>
      </Space>
      <Alert message={`${translations?.allowedFileTypes}: ${ALLOWED_MIME_TYPES.flatMap(t => t.extensions).join(", ")}`} type="info" showIcon />
    </>
  )
}

export default PlaceImageUpload;